import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { logisticsPartners, valueLimits } from "../../constants";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { createLogisticsOrderSchema } from "../../schemas/logistics";
import {
  errorToast,
  handleError,
  successToast,
  validateIntNum,
} from "../../utils/common";
import QuoteModal from "./quoteModal";
import SearchLocality from "./searchLocality";

const LogisticsReqForm = ({ orderData, setOrderData, setParentModal }: any) => {
  const privateInstance = useAxiosInstance();
  const [quoteLoading, setQuoteLoading] = useState(false);
  const [quoteData, setQuoteData] = useState<any>({});
  const [deliveryPartner, setDeliveryPartner] = useState("");
  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const getQuote = async (
    latM: number,
    longM: number,
    latC: number,
    longC: number,
    deliveryPartner: string,
    pickupLocality: string,
    dropLocality: string
  ) => {
    if (!latM || !longM || !latC || !longC) {
      errorToast("Please provide valid pickup and drop lat-long");
      return;
    } else if (!deliveryPartner) {
      errorToast("Please select a delivery partner");
      return;
    }
    let payload = {
      order_id: orderData?.order_id,
      delivery_partner: deliveryPartner,
      optimised_route: true,
      pickup_details: [
        {
          address: pickupLocality || "",
          lat: latM,
          lng: longM,
          reference_id: "pickup-ref",
        },
      ],
      drop_details: [
        {
          address: dropLocality || "",
          lat: latC,
          lng: longC,
          reference_id: "drop-ref1",
        },
      ],
    };
    setQuoteLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V2}/logistics/quote`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setQuoteData(result.data.response);
        setDeliveryPartner(deliveryPartner);
        setShowQuoteModal(true);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setQuoteLoading(false);
    }
  };

  const createLogisticsTask = async (values: any) => {
    let payload: any = {
      order_id: orderData?.order_id,
      delivery_partner: values.delivery_partner,
      pickup_details: [
        {
          reference_id: "pick_ref_1",
          special_instructions: values.merchant_special_ins || "",
          address: {
            street_address_1: values.merchant_address,
            lng: Number(values.merchant_long),
            lat: Number(values.merchant_lat),
            contact_details: {
              name: values.merchant_name,
              phone_number: values.merchant_mobile,
            },
          },
        },
      ],
      drop_details: [
        {
          reference_id: "drop_ref_2",
          special_instructions: values.customer_special_ins || "",
          address: {
            street_address_1: values.customer_address,
            lng: Number(values.customer_long),
            lat: Number(values.customer_lat),
            contact_details: {
              name: values.customer_name,
              phone_number: values.customer_mobile,
            },
          },
          otp_required: values.otp_required,
        },
      ],
    };

    if (values.cod && Number(values.cod) > 0) {
      payload = {
        ...payload,
        drop_details: [
          {
            ...payload.drop_details[0],
            payment_data: {
              payment_method: "COD",
              amount: Number(values.cod_amount),
            },
          },
        ],
      };
    }

    setSubmitLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V2}/logistics/create`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        if (setParentModal) {
          setParentModal(false);
        } else {
          setOrderData([]);
        }
        successToast("Task created successfully");
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <>
      <Box mt={2}>
        <Formik
          initialValues={{
            merchant_name: orderData?.merchant_details
              ? orderData.merchant_details.name
              : "",
            merchant_mobile: orderData?.merchant_details
              ? orderData.merchant_details.mobile
              : "",
            merchant_address: orderData?.merchant_details
              ? orderData.merchant_details.formatted_address
              : "",
            merchant_lat: orderData?.merchant_details
              ? orderData.merchant_details.coordinates?.split(",")[0]
              : "",
            merchant_long: orderData?.merchant_details
              ? orderData.merchant_details.coordinates?.split(",")[1]
              : "",
            merchant_special_ins: "medicine items, please handle with care",
            delivery_partner: "",
            customer_name: orderData?.customer_details
              ? orderData.customer_details.name
              : "",
            customer_mobile: orderData?.customer_details
              ? orderData.customer_details.mobile
              : "",
            customer_address: orderData?.customer_details?.address_details
              ? orderData.customer_details.address_details.address
              : "",
            customer_lat: orderData?.customer_details?.address_details
              ? orderData.customer_details.address_details.coordinates?.split(
                  ","
                )[0]
              : "",
            customer_long: orderData?.customer_details?.address_details
              ? orderData.customer_details.address_details.coordinates?.split(
                  ","
                )[1]
              : "",
            customer_special_ins: "Call customer for address confirmation",
            cod: false,
            cod_amount: "",
            otp_required: true,
            merchant_locality: "",
            customer_locality: "",
          }}
          validationSchema={createLogisticsOrderSchema}
          onSubmit={(values: any, actions: any) => {
            actions.setSubmitting(false);
            createLogisticsTask(values);
          }}
        >
          {(props: any) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              isSubmitting,
              setFieldValue,
            } = props;
            return (
              <Form>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box width={{ xs: "100%", md: "50%" }} pr={{ xs: 0, md: 4 }}>
                    <Typography my={1} variant="h6">
                      Pickup Details:
                    </Typography>
                    <TextField
                      fullWidth
                      id="merchant_name"
                      label="Merchant Name"
                      size="small"
                      value={values.merchant_name || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      onChange={(e) =>
                        setFieldValue("merchant_name", e.target.value)
                      }
                      onBlur={handleBlur}
                      helperText={
                        errors.merchant_name && touched.merchant_name
                          ? errors.merchant_name
                          : ""
                      }
                      error={
                        errors.merchant_name && touched.merchant_name
                          ? true
                          : false
                      }
                      InputLabelProps={{
                        required: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      id="merchant_mobile"
                      label="Merchant Mobile"
                      size="small"
                      value={values?.merchant_mobile || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      onChange={(e: any) =>
                        setFieldValue(
                          "merchant_mobile",
                          validateIntNum(e.target.value, valueLimits.MOBILE)
                        )
                      }
                      onBlur={handleBlur}
                      helperText={
                        errors.merchant_mobile && touched.merchant_mobile
                          ? errors.merchant_mobile
                          : ""
                      }
                      error={
                        errors.merchant_mobile && touched.merchant_mobile
                          ? true
                          : false
                      }
                      InputLabelProps={{
                        required: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      id="merchant_address"
                      label="Merchant Address"
                      size="small"
                      value={values.merchant_address || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      onChange={(e) =>
                        setFieldValue("merchant_address", e.target.value)
                      }
                      onBlur={handleBlur}
                      helperText={
                        errors.merchant_address && touched.merchant_address
                          ? errors.merchant_address
                          : ""
                      }
                      error={
                        errors.merchant_address && touched.merchant_address
                          ? true
                          : false
                      }
                      InputLabelProps={{
                        required: true,
                      }}
                    />
                    <SearchLocality
                      autocompleteLabel="Search merchant locality"
                      orderData={orderData}
                      setFieldValue={setFieldValue}
                    />
                    <Stack direction="row" justifyContent="space-between">
                      <TextField
                        fullWidth
                        id="merchant_lat"
                        label="Latitude"
                        size="small"
                        value={values.merchant_lat || ""}
                        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                        onChange={(e) =>
                          setFieldValue("merchant_lat", e.target.value)
                        }
                        onBlur={handleBlur}
                        helperText={
                          errors.merchant_lat && touched.merchant_lat
                            ? errors.merchant_lat
                            : ""
                        }
                        error={
                          errors.merchant_lat && touched.merchant_lat
                            ? true
                            : false
                        }
                        InputLabelProps={{
                          required: true,
                        }}
                      />
                      <TextField
                        fullWidth
                        id="merchant_long"
                        label="Longitude"
                        size="small"
                        value={values.merchant_long || ""}
                        sx={{ mt: 1.5 }}
                        onChange={(e) =>
                          setFieldValue("merchant_long", e.target.value)
                        }
                        onBlur={handleBlur}
                        helperText={
                          errors.merchant_long && touched.merchant_long
                            ? errors.merchant_long
                            : ""
                        }
                        error={
                          errors.merchant_long && touched.merchant_long
                            ? true
                            : false
                        }
                        InputLabelProps={{
                          required: true,
                        }}
                      />
                    </Stack>
                    <TextField
                      fullWidth
                      id="merchant_special_ins"
                      label="Pickup Instructions"
                      placeholder="Pickup instructions"
                      multiline
                      rows={4}
                      variant="outlined"
                      value={values.merchant_special_ins}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      onChange={(e) =>
                        setFieldValue("merchant_special_ins", e.target.value)
                      }
                      onBlur={handleBlur}
                      helperText={
                        errors.merchant_special_ins &&
                        touched.merchant_special_ins
                          ? errors.merchant_special_ins
                          : ""
                      }
                      error={
                        errors.merchant_special_ins &&
                        touched.merchant_special_ins
                          ? true
                          : false
                      }
                      InputLabelProps={{
                        required: true,
                      }}
                    />
                    <FormControl
                      size="small"
                      fullWidth
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      error={
                        errors.delivery_partner && touched.delivery_partner
                          ? true
                          : false
                      }
                    >
                      <InputLabel id="delivery-partner-label">
                        Delivery Partner
                      </InputLabel>
                      <Select
                        labelId="delivery-partner-label"
                        name="delivery_partner"
                        id="delivery_partner"
                        value={values.delivery_partner}
                        label="Delivery Partner"
                        onChange={(e: SelectChangeEvent) => {
                          setFieldValue("delivery_partner", e.target.value);
                        }}
                        onBlur={handleBlur}
                      >
                        {/* {orderData?.logistics &&
                        orderData.logistics.attempted_partners?.includes(
                          "dunzo"
                        ) ? null : (
                          <MenuItem value="dunzo">Dunzo</MenuItem>
                        )}
                        {orderData?.logistics &&
                        orderData.logistics.attempted_partners?.includes(
                          "porter"
                        ) ? null : (
                          <MenuItem value="porter">Porter</MenuItem>
                        )}
                        {orderData?.logistics &&
                        orderData.logistics.attempted_partners?.includes(
                          "zomato"
                        ) ? null : (
                          <MenuItem value="zomato">Zomato</MenuItem>
                        )} */}
                        {logisticsPartners
                          .filter((x) => x.isActive)
                          .map((item: any, index: number) => {
                            return (
                              <MenuItem key={index} value={item.value}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {errors.delivery_partner && touched.delivery_partner && (
                        <FormHelperText>
                          {errors.delivery_partner}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                  <Box width={{ xs: "100%", md: "50%" }} pr={{ xs: 0, md: 4 }}>
                    <Typography my={1} variant="h6">
                      Drop Details:
                    </Typography>
                    <TextField
                      fullWidth
                      id="customer_name"
                      label="Customer Name"
                      size="small"
                      value={values.customer_name || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      onChange={(e) =>
                        setFieldValue("customer_name", e.target.value)
                      }
                      onBlur={handleBlur}
                      helperText={
                        errors.customer_name && touched.customer_name
                          ? errors.customer_name
                          : ""
                      }
                      error={
                        errors.customer_name && touched.customer_name
                          ? true
                          : false
                      }
                      InputLabelProps={{
                        required: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      id="customer_mobile"
                      label="Customer Mobile"
                      size="small"
                      value={values?.customer_mobile || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      onChange={(e: any) =>
                        setFieldValue(
                          "customer_mobile",
                          validateIntNum(e.target.value, valueLimits.MOBILE)
                        )
                      }
                      onBlur={handleBlur}
                      helperText={
                        errors.customer_mobile && touched.customer_mobile
                          ? errors.customer_mobile
                          : ""
                      }
                      error={
                        errors.customer_mobile && touched.customer_mobile
                          ? true
                          : false
                      }
                      InputLabelProps={{
                        required: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      id="customer_address"
                      label="Customer Address"
                      size="small"
                      value={values.customer_address || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      onChange={(e) =>
                        setFieldValue("customer_address", e.target.value)
                      }
                      onBlur={handleBlur}
                      helperText={
                        errors.customer_address && touched.customer_address
                          ? errors.customer_address
                          : ""
                      }
                      error={
                        errors.customer_address && touched.customer_address
                          ? true
                          : false
                      }
                      InputLabelProps={{
                        required: true,
                      }}
                    />
                    <SearchLocality
                      autocompleteLabel="Search customer locality"
                      orderData={orderData}
                      setFieldValue={setFieldValue}
                    />
                    <Stack direction="row" justifyContent="space-between">
                      <TextField
                        fullWidth
                        id="customer_lat"
                        label="Latitude"
                        size="small"
                        value={values.customer_lat || ""}
                        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                        onChange={(e) =>
                          setFieldValue("customer_lat", e.target.value)
                        }
                        onBlur={handleBlur}
                        helperText={
                          errors.customer_lat && touched.customer_lat
                            ? errors.customer_lat
                            : ""
                        }
                        error={
                          errors.customer_lat && touched.customer_lat
                            ? true
                            : false
                        }
                        InputLabelProps={{
                          required: true,
                        }}
                      />
                      <TextField
                        fullWidth
                        id="customer_long"
                        label="Longitude"
                        size="small"
                        value={values.customer_long || ""}
                        sx={{ mt: 1.5 }}
                        onChange={(e) =>
                          setFieldValue("customer_long", e.target.value)
                        }
                        onBlur={handleBlur}
                        helperText={
                          errors.customer_long && touched.customer_long
                            ? errors.customer_long
                            : ""
                        }
                        error={
                          errors.customer_long && touched.customer_long
                            ? true
                            : false
                        }
                        InputLabelProps={{
                          required: true,
                        }}
                      />
                    </Stack>
                    <TextField
                      fullWidth
                      id="customer_special_ins"
                      label="Drop Instructions"
                      placeholder="Drop instructions"
                      multiline
                      rows={4}
                      variant="outlined"
                      value={values.customer_special_ins}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      onChange={(e) =>
                        setFieldValue("customer_special_ins", e.target.value)
                      }
                      onBlur={handleBlur}
                      helperText={
                        errors.customer_special_ins &&
                        touched.customer_special_ins
                          ? errors.customer_special_ins
                          : ""
                      }
                      error={
                        errors.customer_special_ins &&
                        touched.customer_special_ins
                          ? true
                          : false
                      }
                      InputLabelProps={{
                        required: true,
                      }}
                    />
                    <Stack direction={{ xs: "column", md: "row" }}>
                      <FormControlLabel
                        label="Cash on Delivery"
                        sx={{
                          mt: 1.5,
                          mr: { xs: 0, md: 1 },
                          minWidth: "200px",
                        }}
                        control={
                          <Switch
                            id="cod"
                            checked={values.cod}
                            onChange={(e) =>
                              setFieldValue("cod", e.target.checked)
                            }
                            disabled={
                              logisticsPartners.find(
                                (x) => x.value === values.delivery_partner
                              )?.cod
                                ? false
                                : true
                            }
                          />
                        }
                      />
                      {values.cod ? (
                        <TextField
                          fullWidth
                          size="small"
                          id="cod_amount"
                          label="Amount"
                          variant="outlined"
                          value={values.cod_amount}
                          sx={{ mt: 1.5, mr: 0 }}
                          onChange={(e) =>
                            setFieldValue(
                              "cod_amount",
                              validateIntNum(e.target.value, 999999)
                            )
                          }
                          onBlur={handleBlur}
                          helperText={
                            errors.cod_amount && touched.cod_amount
                              ? errors.cod_amount
                              : ""
                          }
                          error={
                            errors.cod_amount && touched.cod_amount
                              ? true
                              : false
                          }
                          InputLabelProps={{
                            required: true,
                          }}
                        />
                      ) : null}
                    </Stack>

                    <FormControlLabel
                      label="OTP Required"
                      sx={{
                        mt: 1.5,
                        mr: { xs: 0, md: 1 },
                        minWidth: "200px",
                      }}
                      control={<Switch id="otp_required" checked={true} />}
                    />
                  </Box>
                </Box>
                <Box textAlign="center" mt={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      getQuote(
                        Number(values.merchant_lat),
                        Number(values.merchant_long),
                        Number(values.customer_lat),
                        Number(values.customer_long),
                        values.delivery_partner,
                        values.merchant_locality,
                        values.customer_locality
                      )
                    }
                    sx={{ mr: 3 }}
                    disabled={quoteLoading}
                  >
                    Get Quote
                  </Button>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    loading={submitLoading}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                    sx={{ mr: 2 }}
                  >
                    Create Task
                  </LoadingButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
      {showQuoteModal && (
        <QuoteModal
          data={quoteData}
          deliveryPartner={deliveryPartner}
          showQuoteModal={showQuoteModal}
          setShowQuoteModal={setShowQuoteModal}
        />
      )}
    </>
  );
};

export default LogisticsReqForm;
