import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import {
  errorToast,
  getMuiTheme,
  handleError,
  parseAndFormateDate,
  successToast,
  textCapitalize,
  transformAllOrder,
} from "../../../../utils/common";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ORDER_STATUS, ORDER_TYPE } from "../../../../enums/order.enum";
import { AuthenticatedUser } from "../../../../interfaces/user.model";
import useUserInfo from "../../../../hooks/useUserInfo";
import { UpdateNotesIcon } from "../../../../assets/icons";
import NotesModal from "../../notesModal";
import OrderDataModal from "../../orderDataModal";
import AssignProviderModal from "../Pending/assignProviderModal";
import ConfirmBookingModal from "./confirmBookingModal";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UploadRxModal from "./uploadRxModal";
import OutForDeliveryModal from "./outForDeliveryModal";
import DeliveredTimeModal from "./deliveredTimeModal";
import DeliveredIcon from "@mui/icons-material/LocalShipping";
import UpdateTimeIcon from "@mui/icons-material/AccessTime";
import {
  API_RESULT,
  API_VERSION,
  USER_ROLE,
} from "../../../../enums/common.enum";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import PhlebotomistModal from "./phlebotomistModal";
import SubmitClaim from "./submitClaim";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllOrderData,
  setSearchText,
} from "../../../../store/actions/useActions";
import KnowlarityCall from "../../../Common/knowlarityCall";
import { commonTableOptions } from "../../../../constants";
import OrderBadges from "../../orderBadges";
import { useIconStyles } from "../../../Helpers/iconStyles";
import CheckIcon from "@mui/icons-material/Check";
import { ThemeProvider } from "@mui/styles";
import ErrorBoundary from "../../../Helpers/ErrorBoundary";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import StatusTimer from "../../../Helpers/statusTimer";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import PharmacyBills from "../../../OrderDetails/pharmacyBills";

const OrdersLastStage: React.FC<{
  data: any;
  orderType: string;
  getOrders: any;
  dataLoading: boolean;
}> = ({ data, orderType, getOrders, dataLoading }) => {
  const [notesModal, setNotesModal] = useState(false);
  const user: AuthenticatedUser = useUserInfo();
  const [orderDataModal, setOrderDataModal] = useState(false);
  const [activePartner, setActivePartner] = useState("");
  const [activePartnerOrderId, setActivePartnerOrderId] = useState("");
  const [activeOrderId, setActiveOrderId] = useState("");
  const [assignProviderModal, setAssignProviderModal] = useState(false);
  const [confirmBookingModal, setConfirmBookingModal] = useState(false);
  const [uploadRxModal, setUploadRxModal] = useState(false);
  const [outForDeliveryModal, setOutForDeliveryModal] = useState(false);
  const [deliveredTimeModal, setDeliveredTimeModal] = useState(false);
  const privateInstance = useAxiosInstance();
  const [bookingCompleteLoading, setBookingCompleteLoading] = useState(false);
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);
  const iconClasses = useIconStyles();

  const handleBookingCompleted = async (
    orderId: string,
    ordType: string,
    visitType: string
  ) => {
    if (
      window.confirm(
        `Are you sure, this will mark order as ${
          ordType === ORDER_TYPE.CONSULTATION || visitType === "self_visit"
            ? "booking completed"
            : "sample collected"
        }?`
      ) === true
    ) {
      let payload: any;
      payload = {
        order_id: orderId,
        type:
          ordType === ORDER_TYPE.CONSULTATION || visitType === "self_visit"
            ? `${ordType}-booking-completed`
            : "sample-collected-update",
      };

      if (ordType === ORDER_TYPE.DIAGNOSTICS && visitType === "home_visit") {
        payload = { ...payload, order_status: "sample_collected" };
      }

      setBookingCompleteLoading(true);
      try {
        const result = await privateInstance.post(
          `${API_VERSION.V1}/orders/update-order`,
          payload
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          ordType === ORDER_TYPE.CONSULTATION
            ? successToast("Booking completed successfully!")
            : successToast("Order status updated as sample collected!");
          //update
          dispatch(
            setAllOrderData(
              transformAllOrder(
                ordersToManage,
                payload.order_id,
                result.data.response
              )
            )
          );
        } else {
          errorToast(result.data.message);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setBookingCompleteLoading(false);
      }
    }
  };

  const manageBookingColumns = [
    {
      name: "partner_order_id",
      label: "PARTNER ORDER ID",
      options: {
        filter: false,
      },
    },
    {
      name: "customer_details.name",
      label: "CUSTOMER NAME",
      options: {
        filter: false,
      },
    },
    {
      name: "customer_details.mobile",
      label: "CUSTOMER MOBILE",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "consultation_type",
      label: "CONSULTATION DETAILS",
      options: {
        filter: orderType === ORDER_TYPE.CONSULTATION ? true : false,
        display: orderType === ORDER_TYPE.CONSULTATION ? true : false,
        searchable: false,
      },
    },
    {
      name: "sample_collection",
      label: "DIAGNOSTIC DETAILS",
      options: {
        filter: orderType === ORDER_TYPE.DIAGNOSTICS ? true : false,
        display: orderType === ORDER_TYPE.DIAGNOSTICS ? true : false,
        searchable: false,
      },
    },
    {
      name: "time_slot",
      label: "CONSULTATION TIME",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "order_partner",
      label: "ORDER PARTNER",
      options: {
        filter: true,
        //filterType: "textField" as FilterType,
        searchable: false,
      },
    },
    {
      name: "order_status",
      label: "ORDER STATUS",
      options: {
        filter: true,
        searchable: false,
      },
    },
    {
      name: "merchant_details.name",
      label: orderType === ORDER_TYPE.MEDICINES ? "PHARMACY INFO" : "LAB INFO",
      options: {
        filter: false,
        display: orderType === ORDER_TYPE.CONSULTATION ? false : true,
        searchable: false,
      },
    },
    {
      name: "merchant_details.mobile",
      label: "MERCHANT MOBILE",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "nearby_merchants",
      label: "PHARMACY INFO",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "invoice_generated_at",
      label: "UPDATED AT",
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "payment_confirmed_at",
      label: "UPDATED AT",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "package_picked_at",
      label: "UPDATED AT",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "order_id",
      label: "ACTION",
      options: {
        filter: false,
        searchable: true,
      },
    },
    {
      name: "invoice_urls",
      label: "INVOICE URLS",
      options: {
        filter: false,
        searchable: true,
        display: false,
      },
    },
    {
      name: "invoice_details.invoice_link",
      label: "INVOICE LINK",
      options: {
        display: false,
        filter: false,
        searchable: false,
      },
    },
    {
      name: "priority",
      label: "PRIORITY",
      options: {
        filter: true,
        display: false,
        searchable: false,
      },
    },
    {
      name: "delivery_details.delivery_person_mobile",
      label: "Delivery Mobile",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "delivery_details.delivery_person_name",
      label: "Delivery Name",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "delivery_slot",
      label: "Delivery Slot",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "express_delivery",
      label: "EXPRESS DELIVERY",
      options: {
        filter: orderType === ORDER_TYPE.MEDICINES ? true : false,
        display: false,
        searchable: false,
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: dataLoading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    customRowRender: (data: any) => {
      return <CustomRowComponent key={data[0]} rowData={data} />;
    },
    searchPlaceholder: "Name/Partner Order ID/Order ID",
    onSearchChange: (val: string) => {
      dispatch(setSearchText(val));
    },
  };

  function CustomRowComponent({ rowData }: any) {
    const [
      partner_order_id,
      customer_name,
      customer_mobile,
      consultation_type,
      sample_collection,
      time_slot,
      order_partner,
      order_status,
      merchant_name,
      merchant_mobile,
      nearby_merchants,
      invoice_at,
      payment_confirmed_at,
      package_picked_at,
      order_id,
      invoice_urls,
      invoice_link,
      priority,
      delivery_person_mobile,
      delivery_person_name,
      delivery_slot,
      express_delivery,
    ] = rowData;
    return (
      <TableRow hover>
        <TableCell
          sx={{
            maxWidth: { sm: "auto", md: "20vw" },
            overflow: "hidden",
            wordBreak: "break-all",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {orderType === ORDER_TYPE.CONSULTATION ? (
              <Typography
                variant="body2"
                sx={{ color: "#0058ff", cursor: "pointer" }}
                onClick={() => {
                  setActivePartner(order_partner);
                  setActivePartnerOrderId(partner_order_id);
                  setOrderDataModal(true);
                }}
              >
                {partner_order_id}
              </Typography>
            ) : orderType === ORDER_TYPE.DIAGNOSTICS ? (
              <Typography
                variant="body2"
                //sx={{ color: "#0058ff", cursor: "pointer" }}
              >
                {partner_order_id}
              </Typography>
            ) : (
              <>
                {order_status === ORDER_STATUS.PAYMENT_COLLECTED ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "#0058ff", cursor: "pointer" }}
                    onClick={() => {
                      setActivePartner(order_partner);
                      setActivePartnerOrderId(partner_order_id);
                      setAssignProviderModal(true);
                    }}
                  >
                    {partner_order_id}
                  </Typography>
                ) : (
                  <Typography variant="body2">{partner_order_id}</Typography>
                )}
              </>
            )}
            <OrderBadges
              priority={priority}
              order_status={order_status}
              partner_order_id={partner_order_id}
              express_delivery={express_delivery}
            />
          </Stack>
          {invoice_link && (
            <Link
              target="_blank"
              href={invoice_link}
              sx={{ textDecoration: "none" }}
            >
              Invoice
              <OpenInNewIcon
                sx={{
                  fontSize: "16px",
                  ml: 0.5,
                  position: "relative",
                  top: "2px",
                }}
              />
            </Link>
          )}
        </TableCell>
        <TableCell>
          <Stack direction="column">
            <Stack direction="row" alignItems="center">
              <KnowlarityCall
                order={{ order_id: order_id, mobile: customer_mobile }}
                showNumber={false}
              />
              <Typography variant="body2">{customer_name}</Typography>
            </Stack>
          </Stack>
        </TableCell>
        {(orderType === ORDER_TYPE.CONSULTATION ||
          orderType === ORDER_TYPE.DIAGNOSTICS) && (
          <TableCell>
            <Stack>
              <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
                {orderType === ORDER_TYPE.CONSULTATION
                  ? textCapitalize(consultation_type)
                  : textCapitalize(sample_collection?.split("_").join(" "))}
              </Typography>
              <Typography variant="body2" sx={{ color: "#718096" }}>
                {time_slot}
              </Typography>
            </Stack>
          </TableCell>
        )}
        <TableCell>
          <Typography variant="body2">{order_partner}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {textCapitalize(order_status?.split("_").join(" "))}
          </Typography>
        </TableCell>
        {orderType !== ORDER_TYPE.CONSULTATION && (
          <TableCell>
            <Stack direction="row">
              {orderType === ORDER_TYPE.MEDICINES ? (
                <>
                  {nearby_merchants?.[0]?.platform === "manual" ? (
                    <Tooltip title={`Platform: Via WhatsApp`}>
                      <WhatsAppIcon
                        sx={{ mr: 0.25 }}
                        color="success"
                        fontSize="small"
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={`Platform: Via App`}>
                      <PhoneAndroidIcon
                        sx={{ mr: 0.25 }}
                        color="success"
                        fontSize="small"
                      />
                    </Tooltip>
                  )}
                  {nearby_merchants?.[0]?.static_qr_enabled ? (
                    <Tooltip title="MedPay QR Pharmacy">
                      <QrCode2Icon
                        sx={{ mr: 0.5 }}
                        fontSize="small"
                        color="primary"
                      />
                    </Tooltip>
                  ) : null}
                </>
              ) : null}
              <Typography variant="body2">{merchant_name}</Typography>
            </Stack>
            <Box display="flex" alignItems="center">
              <KnowlarityCall
                order={{ order_id: order_id, mobile: merchant_mobile }}
                showNumber={true}
                renderSmallIcon={true}
              />
            </Box>
          </TableCell>
        )}
        <TableCell>
          {orderType === ORDER_TYPE.MEDICINES ? (
            <>
              <Typography variant="body2">
                {order_status === ORDER_STATUS.PACKAGE_PICKED
                  ? parseAndFormateDate(package_picked_at)
                  : parseAndFormateDate(payment_confirmed_at)}
              </Typography>
              <StatusTimer
                timeStamp={
                  order_status === ORDER_STATUS.PACKAGE_PICKED
                    ? package_picked_at
                    : payment_confirmed_at
                }
                timerType={
                  order_status === ORDER_STATUS.PACKAGE_PICKED
                    ? "package_picked"
                    : "payment_collected"
                }
                providerPlatform={nearby_merchants?.[0]?.platform}
                deliverySlot={delivery_slot}
              />
            </>
          ) : (
            <Typography variant="body2">
              {parseAndFormateDate(invoice_at)}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Stack direction="row">
            <IconButton
              color="primary"
              sx={{ fontSize: "22px" }}
              onClick={() => {
                setActiveOrderId(order_id);
                setNotesModal(true);
              }}
            >
              <UpdateNotesIcon />
            </IconButton>

            {(orderType === ORDER_TYPE.CONSULTATION ||
              (orderType === ORDER_TYPE.DIAGNOSTICS &&
                sample_collection === "self_visit")) && (
              <>
                <Tooltip title="booking confirmed">
                  <span>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setConfirmBookingModal(true);
                        setActivePartnerOrderId(partner_order_id);
                        setActiveOrderId(order_id);
                        setActivePartner(order_partner);
                      }}
                      disabled={
                        order_status === ORDER_STATUS.BOOKING_CONFIRMED ||
                        order_status === ORDER_STATUS.BOOKING_COMPLETED ||
                        order_status === ORDER_STATUS.INVOICE_SUBMITTED ||
                        order_status === ORDER_STATUS.CLAIM_SUBMITTED ||
                        order_status === ORDER_STATUS.PRESCRIPTION_UPLOADED ||
                        order_status === ORDER_STATUS.REPORTS_GENERATED
                      }
                    >
                      <CheckIcon className={iconClasses.borderedIcon} />
                    </IconButton>
                  </span>
                </Tooltip>

                {(user?.role === USER_ROLE.SUPER_ADMIN ||
                  user?.role === USER_ROLE.ADMIN ||
                  user?.role === USER_ROLE.MANAGER) && (
                  <>
                    {bookingCompleteLoading ? (
                      <CircularProgress size={16} color="success" />
                    ) : (
                      <Tooltip title="booking completed">
                        <span>
                          <IconButton
                            color="success"
                            onClick={() => {
                              handleBookingCompleted(
                                order_id,
                                orderType,
                                orderType === ORDER_TYPE.DIAGNOSTICS
                                  ? "self_visit"
                                  : ""
                              );
                            }}
                            disabled={
                              order_status === ORDER_STATUS.PAYMENT_COLLECTED ||
                              order_status === ORDER_STATUS.BOOKING_COMPLETED ||
                              order_status === ORDER_STATUS.INVOICE_SUBMITTED ||
                              order_status === ORDER_STATUS.CLAIM_SUBMITTED ||
                              order_status ===
                                ORDER_STATUS.PRESCRIPTION_UPLOADED ||
                              order_status === ORDER_STATUS.REPORTS_GENERATED
                            }
                          >
                            <CheckIcon className={iconClasses.borderedIcon} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  </>
                )}
              </>
            )}

            {orderType === ORDER_TYPE.DIAGNOSTICS &&
              sample_collection === "home_visit" && (
                <>
                  <PhlebotomistModal
                    order_status={order_status}
                    order_id={order_id}
                    delivery_person_mobile={delivery_person_mobile}
                    delivery_person_name={delivery_person_name}
                  />
                  {(user?.role === USER_ROLE.SUPER_ADMIN ||
                    user?.role === USER_ROLE.ADMIN ||
                    user?.role === USER_ROLE.MANAGER) && (
                    <>
                      {bookingCompleteLoading ? (
                        <CircularProgress size={16} color="success" />
                      ) : (
                        <Tooltip title="sample completed">
                          <span>
                            <IconButton
                              color="success"
                              onClick={() => {
                                handleBookingCompleted(
                                  order_id,
                                  orderType,
                                  "home_visit"
                                );
                              }}
                              disabled={
                                order_status ===
                                  ORDER_STATUS.SAMPLE_COLLECTED ||
                                order_status === ORDER_STATUS.REPORTS_GENERATED
                              }
                            >
                              <CheckIcon className={iconClasses.borderedIcon} />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </>
                  )}
                </>
              )}

            {orderType === ORDER_TYPE.MEDICINES && (
              <>
                <Tooltip title="Out for delivery">
                  <span>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setOutForDeliveryModal(true);
                        setActiveOrderId(order_id);
                        setActivePartner(order_partner);
                        setActivePartnerOrderId(partner_order_id);
                      }}
                      disabled={
                        order_status === ORDER_STATUS.PACKAGE_PICKED ||
                        order_status === ORDER_STATUS.PACKAGE_DELIVERED
                      }
                    >
                      <DeliveredIcon className={iconClasses.borderedIcon} />
                    </IconButton>
                  </span>
                </Tooltip>
                {user?.role === USER_ROLE.SUPER_ADMIN ||
                user?.role === USER_ROLE.ADMIN ||
                user?.package_delivery_updation === "enabled" ? (
                  <Tooltip title="Mark delivered">
                    <span>
                      <IconButton
                        color="success"
                        onClick={() => {
                          setDeliveredTimeModal(true);
                          setActiveOrderId(order_id);
                          setActivePartner(order_partner);
                          setActivePartnerOrderId(partner_order_id);
                        }}
                        disabled={
                          order_status === ORDER_STATUS.PACKAGE_DELIVERED ||
                          order_status !== ORDER_STATUS.PACKAGE_PICKED
                        }
                      >
                        <CheckIcon className={iconClasses.borderedIcon} />
                      </IconButton>
                    </span>
                  </Tooltip>
                ) : null}

                {order_status === ORDER_STATUS.PACKAGE_PICKED && (
                  <Tooltip title="update timeslot">
                    <span>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setOutForDeliveryModal(true);
                          setActiveOrderId(order_id);
                          setActivePartner(order_partner);
                          setActivePartnerOrderId(partner_order_id);
                        }}
                      >
                        <UpdateTimeIcon className={iconClasses.borderedIcon} />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {order_status === ORDER_STATUS.PACKAGE_DELIVERED &&
                  !invoice_urls && (
                    <Tooltip title="Upload Invoice">
                      <PharmacyBills
                        page="manage-last-tab"
                        orderData={data?.find(
                          (x: any) => x.partner_order_id === partner_order_id
                        )}
                      />
                    </Tooltip>
                  )}
              </>
            )}

            {orderType !== ORDER_TYPE.MEDICINES && (
              <Tooltip
                title={
                  orderType === ORDER_TYPE.CONSULTATION
                    ? "Upload rx"
                    : "Upload reports"
                }
              >
                <span>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setUploadRxModal(true);
                      setActivePartnerOrderId(partner_order_id);
                      setActiveOrderId(order_id);
                      setActivePartner(order_partner);
                    }}
                    disabled={
                      order_status === ORDER_STATUS.PAYMENT_COLLECTED ||
                      order_status === ORDER_STATUS.PRESCRIPTION_UPLOADED ||
                      order_status === ORDER_STATUS.BOOKING_CONFIRMED ||
                      (order_partner === "lombard" &&
                        (order_status === ORDER_STATUS.BOOKING_CONFIRMED ||
                          order_status === ORDER_STATUS.BOOKING_COMPLETED))
                    }
                  >
                    <UploadFileIcon className={iconClasses.borderedIcon} />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            {orderType === ORDER_TYPE.CONSULTATION &&
              order_partner === "lombard" && (
                <SubmitClaim
                  order_status={order_status}
                  partner_order_id={partner_order_id}
                  order_id={order_id}
                />
              )}
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <ErrorBoundary>
      <Box>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title=""
            data={data}
            columns={manageBookingColumns}
            options={options}
          />
        </ThemeProvider>
        {notesModal && (
          <NotesModal
            notesModal={notesModal}
            setNotesModal={setNotesModal}
            orderId={activeOrderId}
          />
        )}
        {orderDataModal && (
          <OrderDataModal
            orderDataModal={orderDataModal}
            setOrderDataModal={setOrderDataModal}
            activePartner={activePartner}
            activePartnerOrderId={activePartnerOrderId}
            orderType={orderType}
            showInvoiceCancel={false}
          />
        )}
        {assignProviderModal && (
          <AssignProviderModal
            assignProviderModal={assignProviderModal}
            setAssignProviderModal={setAssignProviderModal}
            activePartner={activePartner}
            activePartnerOrderId={activePartnerOrderId}
            assignType="re-assign"
          />
        )}
        {confirmBookingModal && (
          <ConfirmBookingModal
            confirmBookingModal={confirmBookingModal}
            setConfirmBookingModal={setConfirmBookingModal}
            orderId={activeOrderId}
            partnerOrderId={activePartnerOrderId}
            orderPartner={activePartner}
            orderType={orderType}
          />
        )}
        {uploadRxModal && (
          <UploadRxModal
            uploadRxModal={uploadRxModal}
            setUploadRxModal={setUploadRxModal}
            activeOrderId={activeOrderId}
            activePartnerOrderId={activePartnerOrderId}
            activePartner={activePartner}
            orderType={orderType}
          />
        )}
        {outForDeliveryModal && (
          <OutForDeliveryModal
            outForDeliveryModal={outForDeliveryModal}
            setOutForDeliveryModal={setOutForDeliveryModal}
            activeOrderId={activeOrderId}
            activePartner={activePartner}
            activePartnerOrderId={activePartnerOrderId}
          />
        )}
        {deliveredTimeModal && (
          <DeliveredTimeModal
            deliveredTimeModal={deliveredTimeModal}
            setDeliveredTimeModal={setDeliveredTimeModal}
            activeOrderId={activeOrderId}
            activePartner={activePartner}
            activePartnerOrderId={activePartnerOrderId}
          />
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default OrdersLastStage;
